// placeholder
@mixin placeholder {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

// transition
@mixin transition($x) {
    transition: $x;
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
}

// transform
@mixin transform($x) {
    transform: $x;
    -webkit-transform: $x;
    -moz-transform: $x;
    -ms-transform: $x;
    -o-transform: $x;
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// gradient
@mixin gradient-bg() {
    background-color: rgb(233,77,4);
    background-image: linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -moz-linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
}
@mixin gradient-bg-hover() {
    background-color: rgb(238,168,73);
    background-image: linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -moz-linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -webkit-linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
    background-image: -ms-linear-gradient( -90deg, rgb(233,77,4) 0%, rgb(238,168,73) 100%);
}



@mixin transition($time) {
    transition: all $time;
  }
  
  @mixin transform($value) {
    transform: $value;
  }
  @mixin border-radius($value) {
    border-radius: $value;
  }
  
  // Placeholder Mixins
  @mixin placeholder {
    &::placeholder {
      @content;
    }
  }
  
  // media queries
  $units: 1px !default;
  
  @mixin max-screen($res-first, $res-last: $res-first) {
    @if $res-first == $res-last {
      @media screen and (max-width: if(unitless($res-first), $res-first*$units, $res-first)) {
        @content;
      }
    } @else if $res-first < $res-last {
      @media screen and (min-width: if(unitless($res-first), $res-first*$units, $res-first)) and (max-width: if(unitless($res-last), $res-last*$units, $res-last)) {
        @content;
      }
    }
  }
  
  @mixin min-screen($res) {
    @media screen and (min-width: if(unitless($res), $res*$units, $res)) {
      @content;
    }
  }
  