/*
-----------------------------------------------------------------------
	Gallery CSS
-----------------------------------------------------------------------
*/
.gallery-area {}

.gallery-item {
	.thumb {
		position: relative;
		cursor: pointer;
		img {
			width: 100%;
		}
		.overlay {
		    align-items: center;
			background-color: rgba($black, .5);
		    display: flex;
			height: 100%;
		    justify-content: center;
			left: 0;
			opacity: 0;
		    pointer-events: none;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 100%;
			@include transition(.3s);
			i {
				color: $white;
				font-size: 36px;
				@include transition(.3s);
			}
		}
	}
	&:hover {
		.thumb {
			.overlay {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}