/*
-----------------------------------------------------------------------
	Blog CSS
-----------------------------------------------------------------------
*/

.blog-area {
  &.blog-default-area {
    padding: 125px 0px;
    @media #{$lg-device} {
      padding: 90px 0px;
    }
    @media #{$sm-device} {
      padding: 80px 0px;
    }
    @media #{$xs-device} {
      padding: 60px 0 80px;
    }
    .section-title {
      margin-bottom: 70px;
      @media #{$md-device} {
        margin-bottom: 50px;
      }
      @media #{$xs-device} {
        margin-bottom: 40px;
      }
    }
  }
}

.blog-border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.post-items-style1 {
  padding-right: 69px;
  @media #{$lg-device} {
    padding-right: 10px;
  }
  .post-item {
    border-bottom: 1px solid rgba($black-333, 0.15);
    margin-bottom: 27px;
    padding-bottom: 24px;
    @media #{$lg-device} {
      display: flex;
    }
    @media #{$xxs-device} {
      display: block;
    }
    .thumb {
      float: left;
      min-width: 138px;
      margin-right: 30px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      @media #{$xxs-device} {
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        transition: all 0.3s;
      }
    }
    .content {
      .title {
        color: $black;
        font-size: 22px;
        font-weight: $font-weight-normal;
        line-height: 30px;
        letter-spacing: -0.25px;
        margin-bottom: 7px;
        position: relative;
        top: -5px;
        @media #{$xs-device} {
          font-size: 16px;
        }
        a {
          color: $black;
          @media #{$md-device} {
            br {
              display: none;
            }
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
      .category {
        color: $body-color;
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-normal;
        margin-bottom: 14px;
      }
      .meta {
        color: $black-999;
        font-size: 14px;
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        a {
          color: $black;
          .icon {
            color: $body-color;
            padding-right: 7px;
          }
          &:hover {
            color: $theme-color;
          }
        }
        span{
          margin: 0 5px;
        }
      }
    }
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &:hover {
      .thumb {
        img {
          @include scale(1.2);
        }
      }
    }
  }
}

.post-items-style2 {
  .post-item {
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    @media #{$xs-device} {
      display: block;
    }
    .thumb {
      float: left;
      min-width: 300px;
      margin-right: 26px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      @media #{$xs-device} {
        float: none;
        min-width: auto;
        margin-bottom: 30px;
        margin-right: 0;
      }
      img {
        width: 100%;
        transition: all 0.3s;
      }
    }
    .content {
      .title {
        color: $black;
        font-size: 24px;
        font-weight: $font-weight-normal;
        line-height: 30px;
        letter-spacing: -0.25px;
        margin-bottom: 21px;
        @media #{$lg-device} {
          font-size: 20px;
        }
        @media #{$sm-device} {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;
        }
        a {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
      .category {
        color: $theme-color;
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 9px;
        position: relative;
        text-transform: uppercase;
        top: -7px;
      }
      .meta {
        color: $black-999;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: -0.15px;
        margin-bottom: 0;
        span{
          margin: 0 5px;
        }
        a {
          color: $black;
          .icon {
            color: $body-color;
            padding-right: 7px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &:hover {
      .thumb {
        img {
          @include scale(1.2);
        }
      }
    }
  }
}

.post-items-style3 {
  .post-item {
    .thumb {
      overflow: hidden;
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        transition: all 0.3s;
      }
    }
    .content {
      .title {
        color: $black;
        font-size: 24px;
        font-weight: $font-weight-normal;
        line-height: 30px;
        letter-spacing: -0.25px;
        margin-bottom: 7px;
        a {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
      .category {
        color: $theme-color;
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        margin-bottom: 11px;
        text-transform: uppercase;
      }
      .meta {
        color: $black-999;
        font-size: 14px;
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        a {
          color: $black;
          .icon {
            color: $body-color;
            padding-right: 7px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
    &:hover {
      .thumb {
        img {
          @include scale(1.2);
        }
      }
    }
  }
}

.post-details-content {
  .post-details-body {
    .content {
      .title {
        color: $black;
        font-size: 36px;
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        line-height: 48px;
        margin-bottom: 65px;
        margin-right: 100px;
        @media #{$sm-device} {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 35px;
        }
        @media #{$xs-device} {
          margin-right: 0;
        }
      }
      .meta {
        color: $black-999;
        font-size: 14px;
        letter-spacing: -0.15px;
        margin-bottom: 37px;
        margin-top: 42px;
        .category {
          color: $theme-color;
          font-size: 14px;
          letter-spacing: 0;
          text-transform: uppercase;
        }
        span {
          display: inline-block;
          margin: 0 6px;
        }
        .author {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
      p {
        color: $body-color;
        font-size: 16px;
        letter-spacing: -0.15px;
        line-height: 30px;
        margin-bottom: 54px;
      }
      h4 {
        font-size: 24px;
        letter-spacing: -0.25px;
        margin-bottom: 34px;
        margin-top: 69px;
        @media #{$sm-device} {
          font-size: 22px;
          margin-bottom: 25px;
          margin-top: 50px;
        }
      }
      .blockquote-area {
        padding: 14px 145px 0px 58px;
        @media #{$sm-device} {
          padding: 0;
        }
        .blockquote-style {
          margin-bottom: 0;
          p {
            color: $black;
            display: flex;
            font-size: 24px;
            letter-spacing: 0.25px;
            line-height: 36px;
            @media #{$lg-device} {
              br {
                display: none;
              }
            }
            @media #{$sm-device} {
              font-size: 20px;
              line-height: 30px;
              margin-bottom: 35px;
            }
            span {
              font-size: 60px;
              margin-right: 7px;
              margin-top: 1px;
            }
          }
        }
      }
      .widget-tags {
        &.tags-style2 {
          border-bottom: 1px solid #e9ebed;
          margin-bottom: 60px;
          padding-bottom: 27px;
          padding-top: 31px;
          @media #{$lg-device} {
            padding-top: 0;
          }
          ul {
            li {
              a {
                padding: 6.5px 12.41px 7.5px;
              }
            }
          }
        }
      }
    }
    .related-post {
      padding: 25px 0 101px;
      @media #{$lg-device} {
        padding: 0 0 65px;
      }
      @media #{$md-device} {
        padding: 0 0 50px;
      }
      .title {
        display: inline-block;
        font-size: 36px;
        font-weight: $font-weight-light;
        letter-spacing: -1.04px;
        line-height: 1.1;
        margin-bottom: 46px;
        span {
          color: $theme-color;
          font-weight: $font-weight-bold;
        }
      }
      .post-items-style3 {
        .post-item {
          .thumb {
            margin-bottom: 18px;
          }
          .content {
            .category {
              margin-bottom: 11px;
              @media #{$sm-device} {
                display: block;
              }
            }
            .title {
              color: $black;
              font-size: 24px;
              font-weight: $font-weight-normal;
              line-height: 30px;
              letter-spacing: -0.25px;
              margin: 0 0 30px 0;
              a {
                color: $black;
                &:hover {
                  color: $theme-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

.comment-area {
  background-color: #eef1f3;
  border-bottom: 1px solid #e5e5e5;
  padding: 125px 0px;
  @media #{$lg-device} {
    padding: 100px 0px;
  }
  @media #{$sm-device} {
    padding: 85px 0px;
  }
  .section-title {
    margin-bottom: 45px;
    .title {
      font-size: 36px;
      margin-bottom: 9px;
    }
    p {
      letter-spacing: -0.2176px;
      margin-bottom: 0;
    }
  }
}

.widget-item {
  .widget-title {
    color: $black;
    font-size: 18px;
    letter-spacing: 0;
    margin-bottom: 47px;
    text-transform: uppercase;
  }
}

.widget-tags {
  ul {
    li {
      color: $black-999;
      display: inline-block;
      font-size: 14px;
      font-weight: $font-weight-normal;
      margin: 0 6px 10px 0;
      a {
        background-color: $white-f3;
        border-radius: 0;
        color: $black-999;
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: 0.148px;
        line-height: 1;
        padding: 10px;
        &:hover {
          background-color: $theme-color;
          color: $white;
        }
      }
      &:nth-child(4) {
        a {
          padding: 7.5px 10px;
        }
      }
    }
  }
  &.tags-style2 {
    padding-top: 1px;
    ul {
      li {
        a {
          background-color: transparent;
          border: 1px solid #d7d7d7;
          letter-spacing: -0.15px;
          padding: 6.5px 12.41px;
          &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
          }
        }
      }
    }
  }
}

.widget-categories {
  ul {
    li {
      border-bottom: 1px solid #d3d8dc;
      color: $black;
      font-size: 14px;
      padding: 17px 0;
      letter-spacing: -0.093px;
      line-height: 1;
      margin-bottom: 0;
      text-transform: uppercase;
      a {
        color: $black;
        display: block;
        font-size: 14px;
        span {
          float: right;
        }
        &:hover {
          color: $theme-color;
        }
      }
      &:first-child {
        padding-top: 1px;
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.widget-blog-post {
  ul {
    li {
      border-bottom: 1px solid #d3d8dc;
      display: flex;
      margin-bottom: 19px;
      padding-bottom: 19px;
      position: relative;
      .thumb {
        margin-right: 20px;
        min-width: 79px;
        img {
          border-radius: 0;
          width: auto;
        }
      }
      .content {
        position: relative;
        top: -5px;
        a {
          color: $black;
          display: block;
          font-size: 14px;
          font-weight: $font-weight-normal;
          letter-spacing: 0;

          &:hover {
            color: $theme-color;
          }
        }
        span {
          color: #aba5a5;
          display: block;
          font-size: 14px;
        }
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.widget-search-box {
  form {
    input {
      background-color: $white-f5;
      border: none;
      box-shadow: none;
      height: 51px;
      padding: 10px 30px;
      width: 100%;
      @include placeholder {
        color: $black-999;
        font-size: 14px;
        letter-spacing: -0.15px;
      }
    }
  }
}

.sidebar-wrapper {
  &.blog-sidebar-wrapper {
    padding-left: 70px;
    padding-top: 3px;
    @media #{$lg-device} {
      padding-left: 15px;
    }
    @media #{$md-device} {
      padding-left: 0;
    }
    .widget-item {
      .widget-title {
        letter-spacing: 0.323px;
        margin-bottom: 37px;
      }

      .widget-search-box {
        margin-bottom: 71px;
      }

      .widget-categories {
        margin-bottom: 71px;
        ul {
          margin-bottom: 0;
        }
      }

      .widget-blog-post {
        margin-bottom: 70px;
        padding-top: 5px;
      }

      @media (max-width: 767px) {
        .widget-blog-post {
          margin-bottom: 50px;
          padding-top: 5px;
        }
      }

      .widget-tags {
        &.tags-style2 {
          ul {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
