/*
-----------------------------------------------------------------------
	Cases CSS
-----------------------------------------------------------------------
*/
.case-area {}

.case-slider-container{
	display: flex;
	flex-direction: column-reverse;
}

.case-item {
	.thumb {
		margin-bottom: 30px;
		img {
			width: 100%;
			@include transition(.3s);
		}
	}
	.content {
		position: relative;
		.name {
			font-size: 18px;
			letter-spacing: 0;
			margin-bottom: 10px;
			a {
				color: $black;
			}
		}
		.category {
			color: $black-888;
			font-size: 14px;
			font-weight: $font-weight-normal;
			letter-spacing: 0;
		}
	}
	&:hover {
		.thumb {
			img {
				border-radius: 3px;
			}
		}
		.content {
			.name {
				a {
					color: $theme-color;
				}
			}
		}
	}
}

.case-slider-container {
	.swiper-pagination {
		align-items: center;
		display: flex;
		margin: 27px auto 0;
		min-height: 15px;
		max-width: 64px;
		position: relative;
		&.swiper-pagination-bullets {
			.swiper-pagination-bullet {
				background-color: #e6e6e6;
				border-radius: 50%;
				height: 10px;
				margin: 0 5px;
				opacity: 1;
				width: 10px;
				@include transition(.3s);
				&:hover {
					background-color: $theme-color;
				}
				&.swiper-pagination-bullet-active {
					background-color: $theme-color;
					height: 14px;
					width: 14px;
				}
			}
		}
	}
}